<template lang="pug">
div
  Form(:data="confirmationPlanning", :isSubmitting="isSubmitting", :isSelectMultiple="true", @submit="create")
</template>

<script>
import { ref } from '@vue/composition-api'
import Form from '../shared/Form.vue'
// eslint-disable-next-line import/no-cycle
import useConfirmationPlanningAdd from './useConfirmationPlanningAdd'

export default {
  components: {
    Form,
  },
  setup() {
    const { create, isSubmitting } = useConfirmationPlanningAdd()

    const confirmationPlanning = ref({})

    return {
      create,
      isSubmitting,
      confirmationPlanning,
    }
  },
}
</script>
